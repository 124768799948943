<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between align-center pr-4">
        <p class="text-h5 font-weight-medium mb-0">
          Historial de todas las Donaciones
        </p>
        <v-spacer></v-spacer>
        <div>
          <div>
            <p class="mb-0 text-caption"><b>Filtros</b></p>
          </div>
          <v-row>
            <v-col cols="12" md="6" class="pt-0">
              <v-select
                label="Seleccionar Grupo"
                hide-details
                :items="groups"
                item-text="group"
                item-value="id_group"
                v-model="search.filter"
                @change="getTransactions"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 py-0">
              <v-select
                label="Seleccione año lectivo"
                hide-details
                :items="years"
                item-text="year"
                item-value="year"
                v-model="search.year"
                @change="getTransactions"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-banner>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="">
            <v-data-table
              :headers="data.headers"
              :items="data.data"
              :items-per-page="5"
              :search="search.search"
              :loading="data.load"
              loading-text="Cargando datos..."
              class="elevation-0 border-solid"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Listado de donaciones
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <span class="text-h6 font-weight-regular"
                    >Total donado por el grupo:
                    <strong>{{ total }}</strong></span
                  >
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search.search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <vue-json-to-csv
                            :json-data="csvData"
                            :labels="labelsData"
                            :disabled="data.data.length == 0"
                            csv-title="Lista De Transacciones"
                            class="black--text"
                          >
                            <v-icon color="green">mdi-file-excel</v-icon>
                            Descargar CSV
                          </vue-json-to-csv>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueJsonToCsv from "vue-json-to-csv";
export default {
  name: "Transactions",
  components: {
    VueJsonToCsv,
  },
  data: () => ({
    data: {
      load: false,
      headers: [
        { text: "Donador frecuente", value: "name" },
        { text: "Grupo al que pertenece", value: "donor_group" },
        { text: "Nombre de Donador", value: "name" },
        { text: "Correo electrónico", value: "email" },
        { text: "Teléfono", value: "phone" },
        { text: "Fecha de Donación", value: "transaction_time" },
        { text: "Monto de Donación", value: "transaction_amount" },
      ],
      data: [],
    },
    groups: [],
    years: [],
    search: {
      filter: 0,
      year: -1,
      search: "",
    },
  }),
  mounted() {
    this.getGroupsByCoordinator();
    this.getYears();
  },
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),

    getDonations: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "tx/all")
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this;
        });
    },

    getGroupsByCoordinator: async function() {
      await this.$http
        .get(this.api + "donor_group/all/groups_donor")
        .then(({ data }) => {
          this.groups = data;
          if (this.groups.length > 0) {
            this.search.filter = this.groups[0].id_group;
            this.getTransactions();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener grupos",
          });
        });
    },

    getYears: async function() {
      await this.$http
        .get(this.api + "tx/years")
        .then(({ data }) => {
          this.years = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener Años lectivos",
          });
        });
    },

    getTransactions: async function() {
      let group = this.search.filter;
      let year = this.search.year;
      let url = "tx/all_group/";
      if (group != 0) {
        url = "tx/all_group/" + group;
      }
      if (year >= 0) {
        url = `tx/all_group/${group}/${year}`;
      }
      this.data.load = true;
      await this.$http
        .get(this.api + url)
        .then(({ data }) => {
          data.forEach((item) => {
            if (item.transaction_amount != null) {
              item.transaction_amount =
                "$ " + parseFloat(item.transaction_amount).toFixed(2);
            } else {
              item.transaction_amount = "$ " + parseFloat(0).toFixed(2);
            }
            if (item.donor_group == null) {
              item.donor_group = "Ninguno";
            } else {
              item.donor_group = item.donor_group.group;
            }
          });
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener Transacciones por grupo",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),

    labelsData() {
      let headers = {};
      let localHeaders = this.data.headers;
      if (localHeaders.length > 0) {
        localHeaders.forEach((header) => {
          let excluir = [];
          if (!this.searchInArray(excluir, header.text)) {
            headers[header.value] = { title: header.text };
          }
        });
      }
      return headers;
    },

    csvData() {
      let data = [];
      //de esta manera le quito la reactividad a la variables
      let localDataJSON = JSON.stringify(this.data.data);
      let localdata = JSON.parse(localDataJSON);
      //transformando data
      if (localdata.length > 0) {
        localdata.forEach((item) => {
          data.push(item);
        });
      } else {
        data.push({});
      }
      return data;
    },

    total() {
      let sum = this.data.data.reduce((prev, curr) => {
        let amount = curr.transaction_amount.slice(2, curr.length);
        return prev + parseFloat(amount);
      }, 0);
      return this.toUSDFormat(sum);
    },
  },
};
</script>

<style></style>
